<template lang="html">
  <section
    class="
      w-screen
      h-screen
      bg-cafe bg-opacity-70
      flex
      justify-center
      items-center
    "
  >
    <h1 class="text-white text-5xl font-bold">404 Page not found</h1>
  </section>
</template>
